.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container{
  display: flex;
  margin-top: 10px;
}

.link{
  text-decoration: none;
  color: inherit;
}

.underlined {
    text-decoration: underline;
}

.custom-card-header-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  background: #292929;
  box-shadow: '120px 120px 100px 120px rgba(0,0,0,0.2)';
  border-radius: 5px;
  padding: 10px 10px 10px 14px;
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
}

.custom-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  background: #292929;
  box-shadow: '120px 120px 100px 120px rgba(0,0,0,0.2)';
  border-radius: 5px;
  padding: 10px 10px 10px 14px;
  margin: 0;
  font-weight: 500;
  font-size: 1.1rem;
}

.custom-card-header span {
  flex: 1
}

.custom-card-header svg {
  font-size: 1.3rem;
  padding-bottom: 0px;
  margin-right: 10px;
}
