.navbar-link {
    &:hover {
      background-color: rgb(240, 240, 255);
    }
    padding: 5px;
    border-radius: 10px;
    margin-left: 5px;
    text-decoration: none;
    color: #555555;
    display: flex;
    alignItems: center
}

.navbar-link-active {
    background-color: rgb(240, 240, 255);
}
