/* CSS for map components */
.leaflet-container {
    /*width: 750px;*/
    height: 650px;
    /*height: 45vh;*/
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
}

.info h4 {
    margin: 0 0 5px;
    color: #777;
}

.legend {
    background-color: lightgray;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.legend i {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    background-color: #ccc; /* Example color */
}